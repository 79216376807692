<template>
  <MainLayout></MainLayout>
</template>

<script>

import MainLayout from '@/components/layouts/MainLayout'

export default {
  components: {
    MainLayout
  },
  metaInfo () {
    return {
      title: 'Home'
    }
  }
}
</script>
